
import axios from 'axios';
export default {
    
    async createItemTypes(params)  {
        return await axios.post(`item_types/create` , params)
    },
    async createItemTypesList(params)  {
        return await axios.post(`item_types/create/list` , params)
    },
    async updateItemTypesColumn(column , value , data)  {
        return await axios.put(`item_types/update_list?${column}=${value}` , data)
    },
    async deleteItemTypesList(list)  {
        return await axios.delete(`item_types/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportItemTypes(column , value)  {
        return await axios.get(`item_types/report?${column}=${value}`)
    },
    async getAllItemTypes()  {
        return await axios.get(`item_types/all`)
    },
    async getOneItemTypes(item_type_id)  {
        return await axios.get(`item_types/all/${item_type_id}`)
    },
    async getItemTypesByColumn(column , value)  {
        return await axios.get(`item_types/filter?column=${column}&value=${value}`)
    },
    async deleteItemTypes(item_type_id)  {
        return await axios.delete(`item_types/delete/${item_type_id}`)
    },
    async updateItemTypes(item_type_id , params)  {
        return await axios.put(`item_types/update/${item_type_id}` , params)
    }
}